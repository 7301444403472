// import request from "../libs/request";
import EntityService from "./entity";

class RoleService extends EntityService {
  constructor(name) {
    super(name)
  }

  // async addRoleActions(role, actions) {
  //   const result = await request({
  //     url: `/${this.name}/${role.id}/actions`,
  //     method: "post",
  //     data: actions,
  //   });
  //   return result;
  // }

  // async updateRoleActions(role, actions) {
  //   const result = await request({
  //     url: `/${this.name}/${role.id}/actions`,
  //     method: "patch",
  //     data: actions,
  //   });
  //   return result;
  // }
}

export default new RoleService("role")

